import { navigationModules as nav } from '@myci/module-index';

export const appNavigation = [
	{
		...nav.boDashboard,
	},
	{
		...nav.boMessages,
	},
	{
		...nav.logs,
	},
	{
		...nav.boHelp,
	},
	{
		...nav.boSubscriptions,
	},
];

export const customNavigation = [];
